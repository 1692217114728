import { Dialog } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
import { Order } from "../../model/Order";
import { Reason } from "../../model/Reason";
import { SupportCaseDb, SupportCaseStatus } from "../../model/SupportCase";
import { ApiClient } from "../../utils/ApiClient";
import { SupportCaseMapper } from "../../utils/SupportCaseMapper";
import { useAuthState } from "../contexts/AuthContext";
import { SupportCaseState, SupportCaseStateAction } from "../state/SupportCaseState";
import { style } from "../styles/styles";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import { Loader } from "./Loader";
import { Modal } from "./Modal";
import { SummaryListing } from "./SummaryListing";

interface ConfirmDialogProps {
  isOpen: boolean;
  setOpen(is: boolean): void;
  supportCase: SupportCaseState;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
  reasonsWithSolutions: Reason[];
}
export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  setOpen,
  supportCase,
  dispatchSupportCaseAction,
  reasonsWithSolutions,
}) => {
  const cancelButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const authState = useAuthState();
  if (authState.authStatus !== "authenticated") {
    throw new Error("should not throw, user must be authenticated to view the modal");
  }

  async function handleClickSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    if (authState.authStatus !== "authenticated") {
      throw new Error("should not throw, user must be authenticated to view the modal");
    }
    setIsLoading(true);

    // send support case info
    const stateToApi = SupportCaseMapper.stateToApi(supportCase);
    const updatedState = await ApiClient.withXAuthToken(authState.authToken)
      .post<SupportCaseDb>("/api/support-cases", {
        ...stateToApi,
        status: SupportCaseStatus.Validating,
      })
      .catch((e) => {
        setIsLoading(false);
        setError(
          "Sorry! Etwas ist schief gelaufen. Die Serviceanfrage konnte nicht übermittelt werden. Gerne kannst du es später nochmals probieren oder du wendest dich direkt an kundenservice@ehrenkind.de ",
        );
        return console.log(e);
      });

    if (!updatedState) return;

    // TODO: Rewrite, make order state entryPoint or class
    const { orderItems, state: orderState } = await ApiClient.withXAuthToken(authState.authToken).get<Order>(
      "/api/orders/",
      stateToApi.ordernumber,
    );
    const apiToState = SupportCaseMapper.apiToState(updatedState, orderItems || [], orderState);

    setOpen(false);

    dispatchSupportCaseAction({
      type: "continue",
      supportCase: apiToState,
    });
  }

  function handleOnClose(): void {
    setIsLoading(false);
    setOpen(false);
  }

  // update status to SUBMITING
  useEffect(() => {
    setError("");
    let isCanceled = false;

    if (isOpen === true && supportCase.status === SupportCaseStatus.Editing && !isCanceled) {
      (async () => {
        dispatchSupportCaseAction({
          type: "update-status",
          status: SupportCaseStatus.Submitting,
        });
      })();
    }
    return () => {
      isCanceled = true;
    };
  }, [dispatchSupportCaseAction, isOpen, supportCase.status]);

  return (
    <Modal cancelButtonRef={cancelButtonRef} handleOnClose={handleOnClose} isOpen={isOpen} setOpen={setOpen}>
      {!isLoading && !error ? (
        <form onSubmit={(e) => handleClickSubmit(e)}>
          <div className="sm:flex sm:items-start">
            <div className="mt-3 sm:mt-0">
              <header>
                <Dialog.Title as="h2" className={`mb-3 ${style.headline1}`}>
                  Übersicht
                </Dialog.Title>
                <p className={`${style.paragraph}`}>
                  Nachdem du die Anfrage abgesendet hast, prüfen wir diese und melden uns in Kürze mit einer passenden
                  Lösung bei dir. Wir arbeiten auf Hochtouren – aufgrund der vielen Nachrichten kann es momentan leider
                  etwas länger dauern, bis wir dir antworten können. Damit sich die Wartezeit nicht noch weiter
                  verlängert, bitten wir dich, von erneuten Nachfragen abzusehen. Deine Nachricht ist bei uns angekommen
                  und wir geben alles, um sie so schnell wie möglich zu bearbeiten. Danke dir für dein Verständnis und
                  deine Geduld!
                </p>
              </header>

              <main className="mt-6">
                <SummaryListing reasonsWithSolutions={reasonsWithSolutions} supportCase={supportCase} />
              </main>

              <aside className="mt-2">
                <div className="py-4 sm:py-5">
                  <h3 className={`${style.headline2}`}>Weitere Informationen</h3>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  {supportCase.items.some((item) => item.isComplaint) && (
                    <div className="mt-1">
                      <textarea
                        id="customerMessage"
                        name="customerMessage"
                        placeholder="Gibt es noch was, dass du uns mitteilen möchtest?"
                        rows={3}
                        className="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                        value={supportCase.customerMessage}
                        onChange={(e): void => {
                          dispatchSupportCaseAction({
                            type: "update-customer-message",
                            customerMessage: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )}

                  <div className="mt-1">
                    <Checkbox
                      isRequired
                      item={{
                        id: "agreement",
                        value: `${
                          supportCase.items.some(
                            (item) => item.returnQuantity > 0 && !item.isComplaint && item.returnFee,
                          )
                            ? "Ich bin mit der Übernahme der Rückversandkosten und den Rücksendebedingungen einverstanden. "
                            : supportCase.items.some(
                                (item) =>
                                  item.returnQuantity > 0 &&
                                  !item.isComplaint &&
                                  item.product?.returnConditions?.[0]?.value,
                              )
                            ? "Ich bin mit den Rücksendebedingungen einverstanden. "
                            : supportCase.items.some(
                                (item) =>
                                  item.returnQuantity > 0 &&
                                  item.product?.returnConditions?.[0]?.value &&
                                  !item.isComplaint &&
                                  (item.solution === "returnWithRefund" ||
                                    item.solution === "returnWithoutRefund" ||
                                    item.solution === "replacement"),
                              )
                            ? "Ich bin mit den Rücksendebedingungen einverstanden. "
                            : ""
                        }Die von mir angegebenen Daten sind korrekt und wahrheitsgemäß.`,
                      }}
                      isChecked={Boolean(supportCase.agreedTerms)}
                      onValueChange={(val: string) => {
                        dispatchSupportCaseAction({
                          type: "update-agree-terms",
                          agreedTerms: supportCase.agreedTerms ? "" : val,
                        });
                      }}
                    />
                  </div>
                </div>
              </aside>
            </div>
          </div>

          <footer className="flex flex-row-reverse mt-11">
            <div className="ml-3">
              <Button variant="primary" type="submit">
                Serviceanfrage einreichen
              </Button>
            </div>
            <Button variant="secondary" type="button" ref={cancelButtonRef} onClick={handleOnClose}>
              Zurück
            </Button>
          </footer>
        </form>
      ) : error ? (
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0">
            <header>
              <Dialog.Title as="h2" className={`mb-3 ${style.headline1}`}>
                Fehler
              </Dialog.Title>
              <p className={`${style.paragraph}`}>{error}</p>
            </header>
          </div>
        </div>
      ) : (
        // This is needed because of a potential focusTrap error
        // Error: There are no focusable elements inside the <FocusTrap />
        <div ref={cancelButtonRef}>
          <Loader isFullHeight={false} className="p-10" />
        </div>
      )}
    </Modal>
  );
};
